import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { getCookieValue, deleteCookie } from './App';  // assuming the component is in the same directory as App.js
import { IconButton, InputAdornment, Modal, Typography, Button, Link, CircularProgress, ThemeProvider, Box, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import { t } from './Util_format';
import { specialCharactersRegex } from './utils/Validator';
import config from './config/env.json';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { isBusinessAccount, sendUserEmailVerification } from './Util_API_calls';
import { theme, LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, LoginLogoMobile, TermsModal, UnauthenticatedLink, UnauthenticatedTitle, ErrorContainer } from '@style/styled.js';
import flag_it from '@images/flag_it.svg';
import flag_en from '@images/flag_en.svg';
import flag_fr from '@images/flag_fr.svg';
import flag_cn from '@images/flag_cn.svg';

function Register() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null); // To handle login errors
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Step 1: State variable for checkbox

  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [htmlContent, setHtmlContent] = useState("");

  const isValidPassword = (password) => {
    // Password length check
    if (password.length < 8) return false;

    // Check for uppercase
    if (!/[A-Z]/.test(password)) return false;

    // Check for special character
    if (!specialCharactersRegex.test(password)) return false;

    return true;
  };

  const handleRegister = async () => {
    setIsProcessing(true);
    deleteCookie();
    const auth = getAuth();

    const first_name = document.getElementById('first_name').value;
    const last_name = document.getElementById('last_name').value;

    // Check if name and surname are present
    if (!first_name.trim() || !last_name.trim()) {
      setError(t('please_type_name_and_surname'));
      setIsProcessing(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        setError(t('invalid_email_format'));
        setIsProcessing(false);
        return;
    }

    if (password !== passwordConfirm) {
      setError(t('passwords_do_not_match'));
      setIsProcessing(false);
      return;
    }

    // Check for password complexity
    if (!isValidPassword(password)) {
      setError(t('password_requirements'));
      setIsProcessing(false);
      return;
    }

    // Step 2: Check if the checkbox is checked
    if (!isChecked) {
      setError(t('please_accept_terms_and_conditions')); // Update error message as needed
      setIsProcessing(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendUserEmailVerification(user.uid);

      // Inform the user that the verification email was sent
      navigate("/login?message=registration_completed_check_your_email");
    } catch (registrationError) {
      switch (registrationError.message) {
        case 'Firebase: Error (auth/email-already-in-use).':
          setError(t("error_register_email_already_exist"));
          break;
        default:
          setError(registrationError.message || "An error occurred.");
          break;
      }
    }
    setIsProcessing(false);
  };

  const handleInputChangeNameSurname = (e) => {
    let updatedValue = e.target.value.replace(/[^A-Za-z ]/g, '');
    updatedValue = updatedValue.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
    e.target.value = updatedValue;
  };

  useEffect(() => {
    // Check for the 'action=clean-cookie' in the URL
    const cleanCookieAction = new URLSearchParams(window.location.search).get('action') === 'clean-cookie';

    if (cleanCookieAction) {
      // If the action is to clean the cookie, delete it
      deleteCookie();
    } else {
      // Otherwise, check for the existence of the firebaseToken
      const token = getCookieValue('firebaseToken');
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (token) {
        if (loginOtpCompleted === 'true') {
          navigate("/home");
        } else {
          const isPushMessage = getCookieValue('otp_delivery_method') === "push";
          if (isPushMessage) {
            navigate("/waiting_push");
          } else {
            navigate("/login_otp");
          }
        }
      }
    }
  }, [navigate]);

  const downloadPdf = (fileName) => {
    const language = getCookieValue("language") === 'en' ? 'en' : 'it';
    const terms_url = `./docs/termini-${language}${isBusinessAccount() ? "-business" : ""}.pdf`;
    const downloadLink = document.createElement('a');
    downloadLink.href = terms_url; // Replace with your actual file path
    downloadLink.download = `${terms_url}.pdf`;
    downloadLink.click();
  };

  useEffect(() => {
    const language = getCookieValue("language") === 'en' ? 'en' : 'it';
    fetch(`./docs/termini-${language}${isBusinessAccount() ? "-business" : ""}.html`) // Adjust the path if necessary
      .then(response => response.text())
      .then(content => {
        setHtmlContent(content);
      });
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Box id="PageRegister">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>
          <UnauthenticatedRightContainer>
            <LoginLogoMobile />
            <UnauthenticatedTitle>
              {t("create_new_account")}
            </UnauthenticatedTitle>
            <Typography variant="body2" gutterBottom >
              {t("already_have_account")}{" "}
              <UnauthenticatedLink href="/login"> {t("login")}</UnauthenticatedLink>
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', mb: 2, marginTop: '0', marginBottom: '0' }}
            >
              <TextField
                label={t('first_name')}
                id="first_name"
                variant="outlined"
                onChange={handleInputChangeNameSurname}
                sx={{ flex: 1 }}
                fullWidth
                margin="normal"
              />
              <TextField
                label={t('last_name')}
                id="last_name"
                variant="outlined"
                onChange={handleInputChangeNameSurname}
                sx={{ flex: 1 }}
                fullWidth
                margin="normal"
              />
            </Stack>

            <TextField
              label={t('email_address')}
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            />

            <TextField
              label={t('password')}
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                    ) : (
                      <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                    )}
                  </InputAdornment>
                )
              }}
            />

            <TextField
              label={t('confirm_password')}
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                    ) : (
                      <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                    )}
                  </InputAdornment>
                )
              }}
            />

            {/* Step 2: Add the checkbox */}
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
              label={t('i_accept_terms_and_conditions')}
            />
            {error && (
              <ErrorContainer isModal={false}>{error}</ErrorContainer>
            )}
            <Button
              variant="contained"

              sx={{
                textTransform: "none",
                backgroundColor: isProcessing
                  ? "#AAAAAA !important"
                  : undefined,
                color: isProcessing ? "#FFFFFF !important" : undefined,
                minWidth: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={handleRegister}
              disabled={isProcessing}
            >
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('create_new_account')}
            </Button>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={() => {
                  document.cookie = `language=it; path=/; samesite=strict`;
                  window.location.reload();
                }}
                sx={{ p: 0.5 }}
                title="Sito in Italiano"
              >
                <Box
                  component="img"
                  src={flag_it}
                  alt="Italian"
                  sx={{ height: '20px', width: '40px' }}
                />
              </IconButton>

              <IconButton
                onClick={() => {
                  document.cookie = `language=en; path=/; samesite=strict`;
                  window.location.reload();
                }}
                sx={{ p: 0.5 }}
                title="Website in English"
              >
                <Box
                  component="img"
                  src={flag_en}
                  alt="English"
                  sx={{ height: '20px', width: '40px' }}
                />
              </IconButton>

              {config.FDT_BRAND_CODE === 'ipm' && (
                <>
                  <IconButton
                    onClick={() => {
                      document.cookie = `language=fr; path=/; samesite=strict`;
                      window.location.reload();
                    }}
                    className="p-2"
                    title="Site en Français"
                  >
                    <Box
                      component="img"
                      src={flag_fr}
                      alt="French"
                      sx={{ height: '20px', width: '40px' }}
                    />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      document.cookie = `language=cn; path=/; samesite=strict`;
                      window.location.reload();
                    }}
                    className="p-2"
                    title="Site en Français"
                  >
                    <Box
                      component="img"
                      src={flag_cn}
                      alt="French"
                      sx={{ height: '20px', width: '40px' }}
                    />
                  </IconButton>
                </>
              )}

            </Box>

            <Typography variant="body2" >
              <Link href="#" onClick={handleOpen}>
                {t('terms_and_conditions')}
              </Link>
            </Typography>

            <Modal
              open={open}
              onClose={handleClose}
            >
              <TermsModal>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    startIcon={<FileDownloadIcon />}
                    onClick={() => downloadPdf()}
                  >
                    {t("terms_and_conditions")}
                  </Button>
                </Box>
                <Box dangerouslySetInnerHTML={{ __html: htmlContent }} />
                <Button variant="contained" onClick={handleClose}>{t('close')}</Button>
              </TermsModal>
            </Modal>
          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );

}

export default Register;
