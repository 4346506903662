import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, CircularProgress, Typography, InputAdornment, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { getCookieValue } from './App';
import { fetchBankAccountBalance, fetchEpayMobileRechargePay, fetchEpayVoucherPin, fetchEpayMooney_pagopa_verify, fetchEpayMooney_pagopa, fetchConfirmEpayOtp, fetchResendEpayOtp } from './Util_API_calls';
import { number_to_italian_currency, italian_currency_to_number, t } from './Util_format';
import MaskedInput from 'react-text-mask';
import Slider from '@mui/material/Slider';
import { NumericFormat } from 'react-number-format';
import { getCurrencySymbol } from './Util_currencies';
import QRScannerComponent from './Util_QR_scanner';
import flag_it from '@images/flag_it.svg';
import { ErrorContainer, SuccessContainer, ModalTitle } from '@style/styled.js';

const FormatItalianPhoneNumber = memo((props) => {
  const { inputRef, ...other } = props;
  const showMask = (value) => value && value.length > 0;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
      showMask={showMask(other.value)}
    />
  );
});

function ModalServicesBuy({ isOpen, onClose, catalog, bankaccounts, onOpenProductsModal, userDetails }) {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const category_name = pathSegments.length > 2 ? pathSegments[2] : "";
  const [phoneNumber1, setPhoneNumber1] = useState(category_name === 'mobile' ? null : userDetails.phone.substring(3));
  const [phoneNumber2, setPhoneNumber2] = useState(category_name === 'mobile' ? null : userDetails.phone.substring(3));
  const [ModalStepNumber, setModalStepNumber] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const navigate = useNavigate();

  const [bankAccountBalance, setBankAccountBalance] = useState(null);
  const [accountId, setAccountID] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [amount, setAmount] = useState(null);
  const [amountMin, setAmountMin] = useState(null);
  const [amountMax, setAmountMax] = useState(null);
  const [notice_number, setNoticeNumber] = useState('');
  const [pa_vat_code, setPaVatCode] = useState('');


  const [setBalanceApiMaintain] = useState(false);
  const [productDetails, setProductDetails] = useState({ name: '', price: 0 });
  const [isProcessing, setIsProcessing] = useState(false);

  const ean = pathSegments.length > 4 ? pathSegments[4] : "";
  const vendor = pathSegments.length > 3 ? pathSegments[3] : "";
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const inputsRef = useRef([]);
  const step2_submitButtonRef = useRef(null);

  const amountInputRef = useRef(null);
  const [email, setEmail] = useState(userDetails.email);

  const [showQRScanner, setShowQRScanner] = useState(true);


  const styles = {
    step_description: {
      paddingBottom: '20px'
    },
  };

  // Helper function to generate image path from EAN
  const getImagePathByEAN = (ean) => {
    const formattedEAN = String(ean).padStart(13, '0');
    if (ean === 'prepare') {
      // payments use the category instead of EAN
      return `/pics/mooney/${category_name}.webp`;
    } else {
      return `/pics/epay/${formattedEAN}.webp`;
    }
  };

  // Helper function to find product details from catalog

  const findProductDetails = useCallback((ean) => {
    const product = catalog.find(item => item.ean === ean);
    if (product) {
      const productName = product.product_label;
      setAmount(product.amount_min);
      setAmountMin(product.amount_min);
      setAmountMax(product.amount_max);
      if (product.amount_min === product.amount_max) {
        return { name: productName, price: amount };
      } else {
        const productPrice = `${product.amount_min} - ${product.amount_max} (Variable)`;
        return { name: productName, price: productPrice };
      }
    }
  }, [catalog, amount]);

  useEffect(() => {
    const productInfo = findProductDetails(ean);
    if (productInfo) {
      setProductDetails(productInfo);
    }
  }, [ean, findProductDetails]);


  useEffect(() => {
    const productInfo = findProductDetails(ean);
    if (productInfo) {
      setProductDetails(productInfo);
    }
  }, [ean, catalog, findProductDetails]);

  const handleBackButtonClick = () => {

    // close modal if pagopa
    if (category_name === 'pagopa') {
      onClose();
      return;
    }
    setShowQRScanner(true); // Re-enable the scanner when going back
    onClose();
    setTimeout(() => {
      const parts = location.pathname.split('/');
      const newUrl = `/services/${parts[2]}/${parts[3]}`;
      navigate(newUrl);
      onOpenProductsModal();
    }, 0);
  };

  useEffect(() => {
    if (bankaccounts[0].account_id) {
      const updateBankAccountBalance = async () => {
        setIsProcessing(true);
        try {
          const balance = await fetchBankAccountBalance(bankaccounts[0].account_id, getCookieValue);
          setBankAccountBalance(number_to_italian_currency(balance));
          setAccountID(bankaccounts[0].account_id);
        } catch (error) {
          const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
          setBalanceApiMaintain(isMaintain);
        }
        setIsProcessing(false);
      };

      updateBankAccountBalance();
    }
  }, [bankaccounts, setBalanceApiMaintain]);

  const handlePhoneNumberChange = (value, setter) => {
    setter(value); // Update the corresponding state
  };

  const fetch_epay_phone_recharge_pay = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (italian_currency_to_number(bankAccountBalance) < amount) {
      setErrorMessage(t('not_enough_funds'));
      setIsProcessing(false);
      return;
    }

    // Retrieve the two phone numbers entered
    const phoneNumber1 = document.getElementById('phone_number_1').value.replace(/\D/g, '');
    const phoneNumber2 = document.getElementById('phone_number_2').value.replace(/\D/g, '');

    // Ensure phone numbers are the same
    if (phoneNumber1 !== phoneNumber2) {
      setErrorMessage(t('phone_numbers_dont_match'));
      setIsProcessing(false);
      return;
    }

    // Regular expression to check if the phone number starts with '3' and is 10 digits long
    const phoneNumberPattern = /^3\d{9}$/;

    // Validate phone numbers against the pattern
    if (!phoneNumberPattern.test(phoneNumber1)) {
      setErrorMessage(t('phone_number_requirements_italy'));
      setIsProcessing(false);
      return;
    }

    // Existing API call

    try {
      const transactionId = await fetchEpayMobileRechargePay(accountId, phoneNumber1, ean, amount);
      setTransactionId(transactionId);
      setModalStepNumber(2);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetch_epay_voucher_pay = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Existing API call
    try {
      const transactionId = await fetchEpayVoucherPin(accountId, ean, amount);
      setTransactionId(transactionId);
      setModalStepNumber(2);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsProcessing(false);
    }
  };



  const fetch_epay_mooney_pay_pagopa = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    const rawPhoneNumber = document.getElementById('phone_number_1').value.replace(/\D/g, '');
    const phone_number = `+39${rawPhoneNumber}`; // Add prefix correctly
    const first_name = document.getElementById('first_name').value;
    const last_name = document.getElementById('last_name').value;

    console.log('Payment API params:', {
      accountId,
      category_name,
      notice_number,
      pa_vat_code,
      first_name,
      last_name,
      phoneNumber1,
      email,
      amount
    });

    if (amount <= 0 || amount === null) {
      setErrorMessage(t('amount_not_set'));
      setIsProcessing(false);
      return;
    }

    if (!first_name) {
      setErrorMessage(t('first_name_fields_missing'));
      setIsProcessing(false);
      return;
    }

    if (first_name.length > 50) {
      setErrorMessage(t('first_name_too_long')); // Make sure to define this in your translation files
      setIsProcessing(false);
      return;
    }

    if (!last_name) {
      setErrorMessage(t('last_name_fields_missing'));
      setIsProcessing(false);
      return;
    }

    if (last_name.length > 50) {
      setErrorMessage(t('last_name_too_long')); // Make sure to define this in your translation files
      setIsProcessing(false);
      return;
    }

    const phoneNumberPattern = /^3\d{9}$/;
    if (!phoneNumberPattern.test(rawPhoneNumber)) {  // Test the raw number before prefix
      setErrorMessage(t('phone_number_requirements_italy'));
      setIsProcessing(false);
      return;
    }

    if (!/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
      setErrorMessage(t('email_format_invalid'));
      setIsProcessing(false);
      return;
    }

    if (italian_currency_to_number(bankAccountBalance) < amount) {
      setErrorMessage(t('not_enough_funds'));
      setIsProcessing(false);
      return;
    }

    // Existing API call

    try {
      const rawPhoneNumber = document.getElementById('phone_number_1').value.replace(/\D/g, '');
      const phone_number = `+39${rawPhoneNumber}`;
    
      // Order and format data exactly as API expects
      const transactionId = await fetchEpayMooney_pagopa(
        pa_vat_code,        // First parameter
        notice_number,      // Second parameter
        email,             // Third parameter
        accountId,         // Fourth parameter
        first_name,        // Fifth parameter
        last_name,         // Sixth parameter
        phone_number       // Seventh parameter
      );
      setTransactionId(transactionId);
      setModalStepNumber(2);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsProcessing(false);
    }
  };


  const handleChangeOTP = (index, value) => {
    //if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
    if (!/^[0-9]$/.test(value)) return;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
    setTimeout(() => {
      if (index < otp.length - 1) {
        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      } else if (index === otp.length - 1 && value) {
        step2_submitButtonRef.current && step2_submitButtonRef.current.focus();
      }
    }, 10);
  };

  const handleChangeAmount = (event, newValue) => {
    setAmount(newValue);
  };

  const handleKeyDown = (e, index) => {
    // if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] === "") {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[prevIndex] = "";
            return newOtp;
          });
          setTimeout(() => {
            inputsRef.current[prevIndex].focus();
          }, 0);
        }
      } else {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = "";
          return newOtp;
        });

        setTimeout(() => {
          inputsRef.current[index].focus();
        }, 0);
      }
    }
  };

  const handleBackspaceOnSubmit = (e) => {
    if (e.key === 'Backspace') {
      const lastInputIndex = otp.length - 1;
      inputsRef.current[lastInputIndex].focus();
      e.preventDefault();
    }
  };

  const handleConfirmEpayOtp = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);

    try {
      await fetchConfirmEpayOtp(transactionId, otp.join(''));
      setModalStepNumber(3);
    } catch (error) {
      // Use a more robust error handling logic
      let errorMsg = error.message;
      if (error.message === "too_many_requests_try_later") {
        errorMsg = t("too_many_requests_try_later");
      } else if (error.code && error.code === 429) { // Handle HTTP 429 Too Many Requests specifically if needed
        errorMsg = t("too_many_requests_try_later");
      } else if (typeof t === "function") {
        errorMsg = t(error.message) || "An unexpected error occurred"; // Ensure t function exists and has a fallback
      }
      setErrorMessage(errorMsg);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleResendEpayOtp = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);

    try {
      await fetchResendEpayOtp(transactionId);
      setSuccessMessage(t('otp_sent_successfully'));
      setIsProcessing(true);
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleInputChangeNameSurname = (e) => {
    let updatedValue = e.target.value.replace(/[^A-Za-z ]/g, '');
    updatedValue = updatedValue.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
    e.target.value = updatedValue;
  };

  const handleInputChangeToUpperCase = (e) => {
    e.target.value = e.target.value.toUpperCase();
  }

  const handleQRCodeDetected = async (qrResult) => {
    if (qrResult && qrResult.text && showQRScanner) { // Only process if scanner is showing
      console.log('QR Code raw data:', qrResult.text);
      const parts = qrResult.text.split('|');

      if (parts.length >= 5) {
        setShowQRScanner(false); // Disable scanner immediately to prevent multiple scans

        const notice_number = parts[2];
        const pa_vat_code = parts[3];

        console.log('Verification API call params:', {
          notice_number,
          pa_vat_code,
          email
        });

        try {
          const verifyResult = await fetchEpayMooney_pagopa_verify(
            pa_vat_code,
            notice_number,
            email
          );

          if (verifyResult.status === "success") {
            setAmount(verifyResult.amount);
            setNoticeNumber(notice_number);
            setPaVatCode(pa_vat_code);
          } else {
            setErrorMessage(t('verification_failed'));
            setShowQRScanner(true); // Re-enable scanner if verification fails
          }
        } catch (error) {
          console.error('Verification failed:', error);
          setErrorMessage(error.message || t('verification_failed'));
          setShowQRScanner(true); // Re-enable scanner if verification fails
        }
      }
    }
  };

  switch (ModalStepNumber) {
    case 2:
      return (
        <>
          <Dialog open={isOpen} onClose={() => !isProcessing && onClose()} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t('confirm_code')}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage && (
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              )}
              <Box style={{
                ...styles.step_description,
                textAlign: 'center',
                width: '100%'
              }}>
                {t("please_enter_6_digit_otp_received_via_sms")}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    inputProps={{
                      maxLength: 1,
                      style: { width: "40px", textAlign: "center" },
                    }}
                    inputRef={(el) => (inputsRef.current[index] = el)}
                    value={digit}
                    onChange={(e) => handleChangeOTP(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleResendEpayOtp}
                variant="contained"
                disabled={isProcessing}              >
                {t('resend_sms')}
              </Button>
              <Button
                ref={step2_submitButtonRef}
                onKeyDown={handleBackspaceOnSubmit}
                onClick={handleConfirmEpayOtp}
                variant="contained"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('verify_otp_and_purchase')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 3:
      return (
        <Dialog open={isOpen} onClose={() => !isProcessing && onClose()} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('operation_successful')}
          </ModalTitle>
          <DialogContent>
            <SuccessContainer isModal={false}>{t('request_sent_to_partner_wait_for_confirmation_sms')}</SuccessContainer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="contained"

              disabled={isProcessing}
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    default:
      return (
        <Dialog open={isOpen} onClose={() => onClose()} maxWidth="sm" fullWidth>
          <DialogTitle id="dialog-title">
            {vendor === 'payments' ? t('pay_bill_' + category_name) : t('buy_product')}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ position: 'relative' }}>
              <img
                src={getImagePathByEAN(ean)}
                alt="Product"
                style={{
                  position: 'absolute',
                  width: '160px',
                  height: 'auto',
                  top: '15px',
                  right: '25px',
                }}
              />
            </Box>
            {/* Vendor Name */}
            {vendor !== 'payments' && (
              <Box sx={{ marginTop: "16px" }}>
                <span>{t('product_name')}:&nbsp;</span>
                {productDetails.name}
              </Box>
            )}
            {/* Bollettino Type Name */}

            {/* Handle Variable Amounts */}
            {vendor !== 'payments' && amountMin === amountMax && (
              <Box sx={{ marginTop: "16px" }}>
                <span>{t("product_price")}: </span>
                {number_to_italian_currency(amount)}
              </Box>
            )}
            {vendor !== 'payments' && amountMin !== amountMax && (
              <Box sx={{
                display: 'flex',       // Use flexbox to layout children inline
                alignItems: 'center',  // Align items vertically in the center
                marginTop: '16px',
                width: '300px'
              }}>
                <Typography sx={{ marginRight: '16px', width: '400px' }}>
                  {t('amount')}: {number_to_italian_currency(amount)}
                </Typography>
                <Slider
                  value={amount}
                  onChange={handleChangeAmount}
                  aria-labelledby="input-slider"
                  min={amountMin}
                  max={amountMax}
                  valueLabelDisplay="auto" // This enables a label that displays the slider’s current value
                  valueLabelFormat={number_to_italian_currency} // Use the function for formatting
                />
              </Box>
            )}
            {/* Handle user typed Amounts */}

            {category_name === 'pagopa' && (
              <>
                {showQRScanner &&
                  <>
                    <InputLabel id="vehicle-type-label">{t('show_your_qr_code')}</InputLabel>
                    <Box
                      sx={{
                        width: 300,
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <QRScannerComponent onCodeDetected={handleQRCodeDetected} />
                    </Box>
                  </>
                }
                <FormControl sx={{ marginTop: "16px", width: '300px' }}>
                  <TextField
                    id="first_name"
                    label={t('first_name')}
                    variant="outlined"
                    onChange={handleInputChangeNameSurname}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ marginTop: "16px", width: '300px' }}>
                  <TextField
                    id="last_name"
                    label={t('last_name')}
                    variant="outlined"
                    onChange={handleInputChangeNameSurname}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ width: '300px' }}>
                  <TextField
                    id="phone_number_1"
                    label={t("telephone_number")}
                    variant="outlined"
                    margin="normal"
                    value={phoneNumber1} // Bind state variable
                    onChange={(e) => handlePhoneNumberChange(e.target.value, setPhoneNumber1)}
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ marginRight: '8px', marginTop: '1px' }}>
                          +39
                        </InputAdornment>
                      ),
                      inputComponent: FormatItalianPhoneNumber,
                    }}
                    inputProps={{
                      style: {
                        width: '120px', // This sets the width of the input field for the phone number.
                      }
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: '300px' }}>
                  <TextField
                    label={t('email_address')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ borderRadius: '1rem', marginTop: '10px' }}
                  />
                </FormControl>
                {!showQRScanner && (
                  <>
                    <FormControl sx={{ marginTop: "16px", width: '300px' }}>
                      <NumericFormat
                        value={amount}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        suffix={" " + getCurrencySymbol("EUR")}
                        customInput={TextField}
                        label={t("amount")}
                        variant="outlined"
                        name="amount"
                        decimalScale={2}
                        fullWidth
                        inputRef={amountInputRef}
                        readonly
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: '#f3f3f3', // Light gray background
                            color: '#595959', // Darker text for contrast
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ marginTop: "16px", width: '300px' }}>
                      <TextField
                        id="notice_number"
                        label={t('notice_number_label')}
                        variant="outlined"
                        fullWidth
                        value={notice_number}
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: '#f3f3f3',
                            color: '#595959',
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ marginTop: "16px", width: '300px' }}>
                      <TextField
                        id="pa_vat_code"
                        label={t('pa_vat_code_label')}
                        variant="outlined"
                        fullWidth
                        value={pa_vat_code}
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: '#f3f3f3',
                            color: '#595959',
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </>
                )}

              </>
            )}

            <Box sx={{ marginTop: "16px" }}>
              <span>{t("account_balance_title")}: </span>
              {bankAccountBalance ? <span> {bankAccountBalance}</span> : <CircularProgress size={16} color="inherit" sx={{ marginLeft: '8px' }} />}
            </Box>

            {category_name === 'mobile' && (
              <>
                <DialogContent>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginLeft: '-20px',
                    marginBottom: '-20px',
                    marginTop: '-10px'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      paddingRight: '8px',
                      paddingTop: '8px',
                    }}>
                      <img
                        src={flag_it}
                        alt="Italian Flag"
                        style={{ width: 24, height: 16 }}
                      />
                    </Box>
                    <TextField
                      id="phone_number_1"
                      label={t("telephone_number_to_recharge")}
                      variant="outlined"
                      margin="normal"
                      value={phoneNumber1} // Bind state variable
                      onChange={(e) => handlePhoneNumberChange(e.target.value, setPhoneNumber1)}
                      type="tel"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ marginRight: '8px', marginTop: '1px' }}>
                            +39
                          </InputAdornment>
                        ),
                        inputComponent: FormatItalianPhoneNumber,
                      }}
                      inputProps={{
                        style: {
                          width: '120px', // This sets the width of the input field for the phone number.
                          padding: '10px', // You can adjust padding if needed
                        }
                      }}
                      sx={{
                        flexGrow: 0, // Prevent the TextField from growing.
                        marginLeft: '8px', // Align with the padding of the other elements.
                      }}
                    />
                  </Box>
                </DialogContent>

                <DialogContent>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginLeft: '-20px',
                    marginBottom: '-20px',
                    marginTop: '-10px'
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      paddingRight: '8px',
                      paddingTop: '8px',
                    }}>
                      <img
                        src={flag_it}
                        alt="Italian Flag"
                        style={{ width: 24, height: 16 }}
                      />
                    </Box>
                    <TextField
                      id="phone_number_2"
                      label={t("confirm_number_to_recharge")}
                      variant="outlined"
                      margin="normal"
                      type="tel"
                      value={phoneNumber2} // Bind state variable
                      onChange={(e) => handlePhoneNumberChange(e.target.value, setPhoneNumber2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ marginRight: '8px' }}>
                            +39
                          </InputAdornment>
                        ),
                        inputComponent: FormatItalianPhoneNumber,
                      }}
                      inputProps={{
                        style: {
                          width: '120px', // This sets the width of the input field for the phone number.
                          padding: '10px', // You can adjust padding if needed
                        }
                      }}
                      sx={{
                        flexGrow: 0, // Prevent the TextField from growing.
                        marginLeft: '8px', // Align with the padding of the other elements.
                      }}
                    />
                  </Box>
                </DialogContent>
              </>
            )}
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBackButtonClick} variant="contained" disabled={isProcessing} sx={{ width: '80px' }}>
              {t('back_button')}
            </Button>
            <Button
              variant="contained"

              disabled={isProcessing}
              onClick={() => {
                if (category_name === 'mobile') {
                  fetch_epay_phone_recharge_pay();
                } else if (category_name === 'pagopa') {
                  fetch_epay_mooney_pay_pagopa();
                } else {
                  fetch_epay_voucher_pay();
                }
              }}
              sx={{ width: vendor === 'payments' ? '80px' : '160px' }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                vendor === 'payments' ? t('pay_button') : t('buy_product')
              )}
            </Button>

          </DialogActions>
        </Dialog>
      );
  }
}

export default ModalServicesBuy;