import React, { useState, useEffect, useCallback } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography, TextField } from '@mui/material';
import { getCookieValue } from './App';
import { t } from './Util_format';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import config from './config/env.json';
import { get_api_url } from './Util_API_calls';
import { ErrorContainer, ModalTitle } from '@style/styled.js';     

const libraries = ["places"]; //


function ModalUpdateResidentialAddress({ onSave, onClose, address = {} }) {
  const [formValues, setFormValues] = useState({
    addr_line_1: address.addr_line_1 || '',
    addr_line_2: address.addr_line_2 || '',
    zip_code: address.zip_code || '',
    city: address.city || '',
    state: address.state || '',
    country: address.country || '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handlePlaceSelected = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.address_components) {
        const addressObject = {
          addr_line_1: "",
          addr_line_2: "",
          zip_code: "",
          city: "",
          state: "",
          country: "",
        };

        let streetNumber = "";
        let route = "";

        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number")) {
            streetNumber = component.long_name;
          } else if (types.includes("route")) {
            route = component.long_name;
          } else if (types.includes("postal_code")) {
            addressObject.zip_code = component.long_name;
          } else if (
            types.includes("locality") ||
            types.includes("administrative_area_level_2")
          ) {
            addressObject.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            addressObject.state = component.long_name;
          } else if (types.includes("country")) {
            addressObject.country = component.long_name;
          }
        });

        // Concatenate street number and route with a comma for Italian address format
        addressObject.addr_line_1 = `${route}, ${streetNumber}`.trim();

        setFormValues(addressObject);
      }
    }
  }, [autocomplete]);

  useEffect(() => {
    if (!autocomplete) {
      console.error("Autocomplete is not defined");
      return;
    }

    const listener = autocomplete.addListener(
      "place_changed",
      handlePlaceSelected
    );
    // Clean up
    return () => {
      if (listener && window.google && window.google.maps) {
        window.google.maps.event.removeListener(listener);
      }
    };
  }, [autocomplete, handlePlaceSelected]);

  const handleSubmit = async () => {
    setErrorMessage('');
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setIsProcessing(true);

    try {
      // Perform the save operation
      await updateResidentialAddress(formValues);
      window.location.reload(); // Refresh the page upon successful update
    } catch (error) {
      setErrorMessage(error.message || t('error_occurred'));
    } finally {
      setIsProcessing(false);
    }
  };

  const validateForm = () => {
    const requiredFields = ['addr_line_1', 'city', 'zip_code', 'country'];
    let missingFields = requiredFields.filter(field => !formValues[field]);

    if (missingFields.length > 0) {
      return `${t('fields_required')}: ${missingFields.join(', ')}`;
    }
    return null;
  };



  async function updateResidentialAddress(data) {
    const token = getCookieValue('firebaseToken');
    const API_URL = await get_api_url();
    
    try {
      const response = await fetch(`${API_URL}/api/user/addresses/residential/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        const responseJson = await response.json();
        if (responseJson.fdt_error_code) {
          throw new Error(responseJson.fdt_error_code);
        }
        throw new Error('API call failed: ' + response.status);
      }
  
      return await response.json();
    } catch (error) {
      setErrorMessage(t(error.message));
    }
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t('update_residential_address_title')}
      </ModalTitle>
      {errorMessage && (
          <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
        )}
      <DialogContent>
        <Typography>{t('message_contact_to_update_address')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          variant="contained"          
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // return (
  //   <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
  //     <ModalTitle>
  //       {t('update_residential_address_title')}
  //     </ModalTitle>
  //     {errorMessage && (
  //       <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
  //     )}
  //     <DialogContent>
  //       <Typography>{t('update_your_details_here_below')}</Typography>
  //       <LoadScript googleMapsApiKey={config.GOOGLE_MAPS_API_KEY_WEB} libraries={libraries} language={'it'}>
  //         <Autocomplete onLoad={setAutocomplete}>
  //           <TextField
  //             margin="normal"
  //             name="search_address"
  //             label={t('search_address')}
  //             fullWidth
  //             variant="outlined"
  //             autoComplete="off"
  //           // Removed onChange handler
  //           />
  //         </Autocomplete>
  //         {/* ... other form fields */}
  //         <TextField
  //           margin="normal"
  //           name="addr_line_1"
  //           label={t('address_line_1')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.addr_line_1}
  //           onChange={handleChangeAddress}
  //         />
  //         <TextField
  //           margin="normal"
  //           name="addr_line_2"
  //           label={t('address_line_2')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.addr_line_2}
  //           onChange={handleChangeAddress}
  //         />
  //         <TextField
  //           margin="normal"
  //           name="zip_code"
  //           label={t('zip_code')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.zip_code}
  //           onChange={handleChangeAddress}
  //         />
  //         <TextField
  //           margin="normal"
  //           name="city"
  //           label={t('city')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.city}
  //           onChange={handleChangeAddress}
  //         />
  //         <TextField
  //           margin="normal"
  //           name="state"
  //           label={t('state')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.state}
  //           onChange={handleChangeAddress}
  //         />
  //         <TextField
  //           margin="normal"
  //           name="country"
  //           label={t('country')}
  //           fullWidth
  //           variant="outlined"
  //           value={formValues.country}
  //           onChange={handleChangeAddress}
  //         />
  //       </LoadScript>
  //     </DialogContent>
  //     <DialogActions>
  //       <Button
  //         onClick={handleSubmit}
  //         variant="contained"

  //         disabled={isProcessing}
  //       >
  //         {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // );
}

export default ModalUpdateResidentialAddress;
