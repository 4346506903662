import { t } from './Util_format';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { TextField, MenuItem } from '@mui/material';
import { setCookieValue } from "./App";

export function renderUserTenancies() {
    // Utility function to get a cookie
    const getAccountsFromCookie = (name) => {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    };

    let personalAccounts = JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
    let businessAccounts = JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');

    const container = document.getElementById('account-in-use');
    if (!container) return;

    const accounts = [...personalAccounts, ...businessAccounts];
    if (businessAccounts.length === 0) {
        accounts.push({
            type: 'business',
            value: 'new-application',
            label: t('open_your_business_account')
        });
    }
    // Create root only if it doesn't exist
    if (!container._reactRootContainer) {
        const root = createRoot(container);
        container._reactRootContainer = root;
    }

    container._reactRootContainer.render(
        <TextField
            select
            name="accountInUse"
            fullWidth
            margin="dense"
            style={{ minWidth: '250px', marginRight: '10px', color: "#8ECAE6", fontFamily: 'Poppins' }}
            value={accounts.find(account => account.inUse)?.value || ''}
            onChange={(event) => handleTenancyChange(event, accounts, personalAccounts, businessAccounts)}
        >
            {accounts.map(account => (
                <MenuItem key={account.value} value={account.value}>
                    {account.label}
                </MenuItem>
            ))}
        </TextField>
    );
}

function handleTenancyChange(event, accounts, personalAccounts, businessAccounts) {
    const selectedTenancy = accounts.find(account => account.value === event.target.value);
    if (selectedTenancy.statusId !== 100) {
        if (selectedTenancy.type === 'business') {
            if (selectedTenancy.business_id) {
                window.location.href = `/kyb/${selectedTenancy.business_id}`;
            } else {
                window.location.href = '/new_account/business';
            }
        } else {
            window.location.href = '/kyc';
        }
        return;
    }

    accounts.forEach(account => account.inUse = false);
    if (selectedTenancy) selectedTenancy.inUse = true;
    setCookieValue('retail_tenancies', JSON.stringify(personalAccounts), 7);
    setCookieValue('business_tenancies', JSON.stringify(businessAccounts), 7);
    setCookieValue('RETAIL_OR_BUSINESS', selectedTenancy.value === personalAccounts[0].value ? 'RETAIL' : 'BUSINESS');
    if (selectedTenancy.type === 'business') {
        setCookieValue('business_id', selectedTenancy.business_id);
    } else {
        setCookieValue('business_id', '');
    }
    renderUserTenancies(); // Re-render to update inUse status
    window.location.reload();
}
