export const SEPA_COUNTRIES = {
    AT: 'Austria',
    BE: 'Belgium', 
    BG: 'Bulgaria',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    EE: 'Estonia',
    FI: 'Finland',
    FR: 'France',
    DE: 'Germany',
    GR: 'Greece',
    HU: 'Hungary',
    IE: 'Ireland',
    IT: 'Italy',
    LV: 'Latvia',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MT: 'Malta',
    NL: 'Netherlands',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ES: 'Spain',
    SE: 'Sweden',
    IS: 'Iceland'
  };
  
  export const isValidSepaCountry = (countryCode) => {
    return Object.keys(SEPA_COUNTRIES).includes(countryCode);
  };